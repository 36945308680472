import { defineStore } from 'pinia'

export const useUserConfig = defineStore(
  'userConfig',
  () => {
    const customerGroupId = ref<number | null>(null)
    const priceCategoryId = ref<number | null>(null)

    function setCustomerGroup(id: number | null) {
      customerGroupId.value = id
    }

    function setPriceCategory(id: number | null) {
      priceCategoryId.value = id
    }

    return {
      customerGroupId,
      priceCategoryId,
      setCustomerGroup,
      setPriceCategory,
    }
  },
  {
    persist: {
      storage: persistedState.cookies,
    },
  }
)
